/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Information from '../components/Information'
import { useNavigate } from 'react-router-dom'
import { getStrapiAbout } from './data/getStrapiAbout'

const About = () => {
  let navigate = useNavigate()
  const [strapiAboutContent, setStrapiAboutContent] = useState([])
  const collectionData = 'about-homepages?populate[about_homepage_image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiAbout(collectionData)
      setStrapiAboutContent(data)
    }
    fetchData()
  }, [])

  const routeChange = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    let path = '/about'
    navigate(path)
  }

  return (
    <div>
      {strapiAboutContent.map(({ id, about_title, about_subtitle, about_content, about_contentAbout, button_text, background }) => (
        <div className="w-full" id="about" key={id}>
          <div className="mx-auto mt-20 container">
            <Information
              title={about_title}
              subtitle={about_subtitle}
              content={about_content}
              contentAbout={about_contentAbout}
            />
          </div>
          <div className="flex items-center justify-center mb-6">
            <button
              onClick={routeChange}
              className="md:w-[25%] uppercase hover:delay-0 shadow-xl text-white bg-blue-900 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
             {button_text}
            </button>
          </div>
          {/* Assuming `Family` is imported or defined somewhere */}
          <img src={background} className="w-[80%] mx-auto my-0 rounded-md" alt="background image" />
        </div>
      ))}
    </div>
  )
}

export default About
/* eslint-enable */
