import React, { useState } from 'react'
import Hero from './Hero'
import About from './About'
import Services from './Services'
import Contact from './Contact'
import Carousel from './Carousel'
import Gallery from './Gallery'

const Home = () => {
  const [option, setOption] = useState('House Keeping')

  const updateOption = (newOption) => {
    console.log('El estado ha sido modificado')
    setOption(newOption)
  }

  return (
    <>
      <Hero />
      <div className="flex items-center justify-center">
        <div className="max-w-5xl">
          <About />
          <Services />
          <div className="flex items-center justify-center bg-neutral-100/90 dark:bg-slate-800/90">
            <div className="max-w-5xl">
              <Carousel updateOption={updateOption} />
              <Gallery option={option} />
            </div>
          </div>
          <div className="my-4">
            <Contact />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
