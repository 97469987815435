/* eslint-disable */
const token = 'c4e92555aa3685a7f2d6352cb04a9cefb3489e86920dce0b7afb991e1e287c39acd91a1a86b29f6781add6f4698e2bf9edf4b1b1f121b772d2ebedeaa5c46b2a51f67ca6833442bb7c152714af0e670d5ba512a898df9e52242cf5a470c67ae3f07b537273148adc1fd2a6cd53440d727aac26b06eb42b8916ac9479211fa80e'
const STRAPI_URL = 'https://ski-mountain-services.telluridedigitalworks.com/strapi'

export const getStrapiServices = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        service_title: attributes.service_title,
        service_subtitle: attributes.service_subtitle,
        service_content: attributes.service_content,
        service_button: attributes.service_button,
        service_cards: attributes.category?.data.map ( ({ attributes, id}) => ({
          id: id,
          title: attributes.title,
          subtitle: attributes.subtitle,
          content: attributes.content,
          image:  STRAPI_URL + attributes.service_image.data.attributes.url
        }))
      }
    }
  )

  return values
}
/* eslint-enable */
