import React from 'react'
import '../src/scss/App.scss'
import Header from '../src/components/Header'
import HeaderAbout from '../src/components/HeaderAbout'
import Home from './components/Home'
import AboutPage from './components/AboutPage'
import Footer from './components/Footer'
import { Routes, Route, useLocation } from 'react-router-dom'

function App() {
  let location = useLocation()
  location = location.pathname

  return (
    <div>
      <div className="flex flex-col antialiased bg-neutral-100 dark:bg-slate-800 min-h-full h-full">
        {location === '/about' ? <HeaderAbout /> : <Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<AboutPage />} />
        </Routes>
        <Footer />
      </div>
    </div>
  )
}

export default App
