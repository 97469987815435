// images
import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import ButtonToggleMode from './ButtonToggleMode'
import { getStrapiHero } from './data/getStrapiHero'

const Header = () => {
  const [strapiHeroContent, setStrapiHeroContent] = useState([])
  const collectionData = 'heroes?populate[background]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiHero(collectionData)
      setStrapiHeroContent(data)
    }
    fetchData()
  }, [])

  return (
    <div className="bg-neutral-100 dark:bg-slate-800">
      <header id="home">
        {strapiHeroContent?.map(({ hero_title, hero_subtitle, id, background, text_button }) => (
          <div
            key={id}
            className="w-full bg-cover bg-center"
            style={{ backgroundImage: `url(${background})`, height: '32rem' }}>
            <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-40 dark:bg-opacity-60">
              <div className="text-center">
                <h1 className="text-white text-2xl font-semibold uppercase md:text-3xl pb-2">
                  {hero_title}
                </h1>
                <p className="text-white uppercase text-lg font-medium md:text-xl pb-2">
                  {hero_subtitle}
                </p>
                <div className="flex items-center justify-center">
                  <Link
                    to="about"
                    spy
                    smooth
                    className="animate-bounce duration-0 delay-0 mx-1 mt-4 px-4 py-2 bg-white text-dark text-sm font-light rounded-xl dark:bg-slate-600 dark:text-white hover:bg-sky-500 focus:outline-none focus:bg-sky-500">
                    {text_button}
                  </Link>
                  <div className="mt-2 ml-2 bg-white dark:bg-slate-600/90 hover:bg-transparent dark:hover:bg-transparent rounded-md">
                    <ButtonToggleMode />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </header>
    </div>
  )
}

export default Header
