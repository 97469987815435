/* eslint-disable */
const token = 'c4e92555aa3685a7f2d6352cb04a9cefb3489e86920dce0b7afb991e1e287c39acd91a1a86b29f6781add6f4698e2bf9edf4b1b1f121b772d2ebedeaa5c46b2a51f67ca6833442bb7c152714af0e670d5ba512a898df9e52242cf5a470c67ae3f07b537273148adc1fd2a6cd53440d727aac26b06eb42b8916ac9479211fa80e'
const STRAPI_URL = 'https://ski-mountain-services.telluridedigitalworks.com/strapi'

export const getStrapiAboutPage = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        title: attributes.title,
        image: STRAPI_URL + attributes.image.data.attributes.url,
        first_section: attributes.first_section.data,
        second_section: attributes.second_section.data,
        third_section: attributes.third_section.data,
        fourth_section: attributes.fourth_description.data,
        fifth_section: attributes.fifth_description.data,
        sixth_section: attributes.sixth_description.data,
        seventh_section: attributes.seventh_description.data,
        gretings_footer: attributes.greetings_footer.data
      }
    }
  )

  return values
}
/* eslint-enable */
