export default function Footer() {
  //  {/* prettier-ignore */}
  return (
    <footer className="bottom-0 p-4 rounded-lg m-0 md:px-6 md:py-8 w-full">
      <div className="flex flex-col items-center justify-center">
        <div className="flex my-6 space-x-6 sm:justify-center sm:mt-0">
          <a
            href="https://www.facebook.com/profile.php?id=100088334042151"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>
        <span className="text-sm text-gray-500 text-center dark:text-gray-400">
          © 2024
          <a
            href="https://www.telluridedigitalworks.com"
            target="blank"
            className="hover:underline">
            Telluride Digital Works™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  )
}
