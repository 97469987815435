/* eslint-disable */
import { useState } from 'react'
import { Link } from 'react-scroll'
import logo from '../assets/home/logo.png'

const Header = () => {

  return (
    <nav className="bg-white dark:bg-slate-600/50">
      <div className="flex justify-between items-center px-8 md:px-10 xl:px-40 space-x-7">
        <a href="/" className='flex flex-row items-center py-4 px-2'>
          <img src={logo} alt="Logo" className="h-8 w-8 mr-2 rounded-2xl" />
          <span className="font-semibold md:text-md text-gray-500 dark:text-white text-sm md:text-lg">
            Ski Mountain Services
          </span>
        </a>
        <div className="flex text-center text-white space-x-2">
          <a href="/" className="py-4 text-center capitalize px-2 hover:border-b-2 hover:bg-orange-600 text-gray-500 dark:text-white font-semibold dark:hover:text-white transition duration-300">Home</a>
        </div>
      </div>
    </nav>
  )
}

export default Header
