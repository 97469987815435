/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Information from '../components/Information'
import { Link } from 'react-scroll'
import { getStrapiServices } from './data/getStrapiServices'

const Services = () => {
  const [strapiServicesContent, setStrapiServicesContent] = useState([])
  const collectionData = 'services-containers?populate[category][populate][service_image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiServices(collectionData)
      setStrapiServicesContent(data)
    }
    fetchData()
  }, [])

  const CardServices = ({ name, subtitle, content, image, index }) => {
    switch (index % 2) {
      case 0:
        return (
          <div key={index} className="shadow-xl bg-neutral-200 dark:bg-slate-700 my-10 rounded-xl">
            <div className="grid sm:md:grid-cols-3 grid-cols-1">
              <div className="px-4 flex items-center justify-center col-span-2">
                <Information title={name} subtitle={subtitle} content={content} edit={false} />
              </div>
              <img
                className="rounded-xl w-full h-full shadow-xl sm:md:scale-105 scale-100"
                src={image}
                alt="Services Image"
              />
            </div>
          </div>
        )
      case 1:
        return (
          <div key={index} className="shadow-xl bg-neutral-200 dark:bg-slate-700 rounded-xl">
            <div className="grid sm:md:grid-cols-3 grid-cols-1">
              <img
                className="rounded-xl w-full h-full shadow-xl sm:md:scale-105 scale-100"
                src={image}
                alt="Services Image"
              />
              <div className="px-4 flex items-center justify-center col-span-2">
                <Information title={name} subtitle={subtitle} content={content} edit={false} />
              </div>
            </div>
          </div>
        )
      default:
        console.log('Fallo')
    }
  }

  return (
    <div className="px-[2rem] w-full bg-neutral-100 dark:bg-slate-800" id="services">
      {strapiServicesContent?.map(({ id, service_title, service_subtitle, service_content, service_button, service_cards})=>(
        <div className="mx-auto mt-20 mb-14 container" key={id}>
          <Information
            title={service_title}
            subtitle={service_subtitle}
            content={service_content}
          />
          <div className="flex items-center justify-center">
            <Link
              spy
              smooth
              to="contact"
              className="shadow-xl text-white bg-blue-900 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {service_button}
            </Link>
          </div>
          {service_cards.map(({id, title, subtitle, content, image}, index) => (
            <CardServices
              key={id}
              name={title}
              subtitle={subtitle}
              content={content}
              image={image}
              index={index}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Services
/* eslint-disable */
