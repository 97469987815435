/* eslint-disable */
import React from "react"

const AboutResponsiveness = ({ id, second_section, third_section, fourth_section, fifth_section, sixth_section, seventh_section }) => {
 return (
    <React.Fragment key={id}>
      <div className="lg:hidden">
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          {second_section.map((item, index) => (
            <p key={`second-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          { third_section.map((item, index) => (
            <p key={`third-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          { fourth_section.map((item, index) => (
            <p key={`fourth-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          {fifth_section.map((item, index) => (
            <p key={`fifth-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          {sixth_section.map((item, index) => (
            <p key={`sixth-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          {seventh_section.map((item, index) => (
            <p key={`seventh-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
      </div>
      <div className="hidden text-sm lg:text-lg lg:grid lg:grid-cols-2 lg:gap-6 lg:mt-[3rem] lg items-center w-[95%]">
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          {second_section.map((item, index) => (
            <p key={`second-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          {fifth_section.map((item, index) => (
            <p key={`fifth-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          { third_section.map((item, index) => (
            <p key={`third-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          {sixth_section.map((item, index) => (
            <p key={`sixth-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          { fourth_section.map((item, index) => (
            <p key={`fourth-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
        <div className="lg:w-50% lg:flex lg:items-start lg:flex-col lg:justify-start">
          {seventh_section.map((item, index) => (
            <p key={`seventh-${index}`} className="mb-4 text-justify lg:text-start">
              {item.content}
            </p>
            ))
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutResponsiveness
  /* eslint-enable */
