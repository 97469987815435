import React, { useState, useEffect } from 'react'
import { getStrapiAboutPage } from './data/getStrapiAboutSection'
import AboutResponsiveness from './AboutResposiveness'

const AboutPage = () => {
  /* eslint-disable */
  const [aboutData, setAboutData] = useState([])
  const collectionData = 'about-sections?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiAboutPage(collectionData)
      setAboutData(data)
    }
    fetchData()
  },[])


  return (
    <div className="my-[50px] flex flex-col px-[20px] lg:px-[100px] lg:h-full items-center justify-center  lg:flex-col">
      {aboutData.map(({ id, title, image, first_section, second_section, third_section,fourth_section, fifth_section, sixth_section, seventh_section, gretings_footer }) => (
         <React.Fragment key={id}>
            <div key={`about-section-${id}`} className='flex flex-col lg:flex-row justify-center items-center'>
            <div className="flex h-full w-[80%]s lg:w-[50%]">
              <img
                src={image}
                alt="Hero Image"
                className="w-full h-full rounded-lg object-cover md:mx-auto  md:h-[70%]"
              />
            </div>
            <div className="container mx-auto py-8 lg:text-center flex flex-col items-center justify-center w-[95%]">
              <div className='lg:px-8 lg:w-[85%] xl:w-[70%] '>
                <h2 className="text-3xl text-center font-bold mb-4">{title}</h2>
                {first_section.map((item, index) => (
                  <p key={`first-section-${index}`} className="mb-4 text-justify">
                    {item.content}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div key={`about-responsive-${id}`}>
            <AboutResponsiveness
              id={id}
              second_section={second_section}
              third_section={third_section}
              fourth_section={fourth_section}
              fifth_section={fifth_section}
              sixth_section={sixth_section}
              seventh_section={seventh_section}
            />
          </div>
          <div className="lg:mt-[4rem]">
            {gretings_footer.map((item, index) => (
              <p key={`greeting-${index}`} className="mb-4 text-justify lg:text-start">
                {item.content}
              </p>
            ))}
          </div>
         </React.Fragment>
      ))}
    </div>
  )
}

export default AboutPage
