const Information = ({ title, subtitle, content, contentAbout, edit = true }) => {
  if (edit) {
    return (
      <div className="grid justify-items-center w-full text-center sm:mt-10 sm:mb-4 my-2">
        <p className="sm:md:text-3xl text-xl font-light text-blue-900 dark:text-sky-500 py-2">
          {title}
        </p>
        <p className="sm:md:text-xl text-md text-blue-900 dark:text-sky-400 py-2">{subtitle}</p>
        <p className="sm:md:text-sm text-xs sm:md:px-10 px-5 text-blue-900/80 dark:text-sky-400 py-2">
          {content}
        </p>
        <p className="sm:md:text-sm text-xs sm:md:px-10 px-5 text-blue-900/80 dark:text-sky-400 py-2">
          {contentAbout}
        </p>
      </div>
    )
  } else {
    return (
      <div className="grid justify-items-center w-full text-center sm:md:my-4 my-2">
        <p className="sm:md:text-3xl text-xl font-light text-blue-900 dark:text-sky-500 py-2">
          {title}
        </p>
        <p className="sm:md:text-xl text-md text-gray-600 dark:text-gray-200 py-2">{subtitle}</p>
        <p className="sm:md:text-md text-xs sm:md:px-10 px-5 text-gray-700 dark:text-gray-200 py-2">
          {content}
        </p>
        <p className="sm:md:text-md text-xs sm:md:px-10 px-5 text-gray-700 dark:text-gray-200 py-2">
          {contentAbout}
        </p>
      </div>
    )
  }
}

export default Information
