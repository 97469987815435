const Locations = ({ maps, title }) => {
  return (
    <div className="w-full p-5">
      <div className="rounded shadow-lg">
        <iframe className="w-full h-60" title={title} src={maps} loading="lazy" />
      </div>
      <hr />
      <p className="p-5 sm:text-lg text-sm text-blue-900 dark:text-sky-500 uppercase">{title}</p>
    </div>
  )
}

export default Locations
