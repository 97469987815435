/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Information from '../components/Information'
import FormContact from '../components/FormContact'
import Locations from '../components/Locations'
import { getStrapiContact } from './data/getStrapiContact'

const Contact = () => {
  const [strapiContact, setStrapiContact] = useState([])
  const collectionData = 'contact-sections?populate[contact_image][populate][contact_images]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiContact(collectionData)
      setStrapiContact(data)
    }
    fetchData()
  }, [])

  const LinkTo = ({ url, text }) => (
    <a
      href={url}
      className="flex items-center text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
      <p className="text-center dark:text-white w-full">{text}</p>
    </a>
  )

  return (
    <>
      {strapiContact?.map(
        ({ contact_title, contact_subtitle, ubication_url, ubication_title, contact_image, id }) => (
          <div className="w-full my-10 bg-neutral-100 dark:bg-slate-800" key={id} id="contact">
            <div className="mx-auto mt-24 mb-10 container">
              <div className="grid sm:md:grid-cols-2 grid-cols-1">
                <Information
                  title={contact_title}
                  subtitle={contact_subtitle}
                  edit={false}
                />
                <FormContact />
              </div>
            </div>
            <img className="w-full rounded-t-xl" src={contact_image} alt="Office Image" />
            <section className="mx-auto px-5 pt-5 text-center shadow-xl bg-neutral-200 dark:bg-slate-800 rounded-b-xl">
              <h1 className="pb-3 text-1xl font-semibold uppercase md:text-2xl sm:text-1xl text-blue-900 dark:text-sky-500">
                Find us here
              </h1>
              <div className="flex items-center justify-center">
                <Locations
                  maps={ubication_url}
                  title={ubication_title}
                />
              </div>
              <div className="flex items-center justify-center pb-6">
                <LinkTo url="tel:+1 (970) 708-3940" text="Call Us 📞" />
              </div>
            </section>
          </div>
        ))
      }
    </>
  )
}

export default Contact
/* eslint-enable */
