/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { getStrapiCarousel } from './data/getStrapiCarosuel'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

const Services = ({ updateOption }) => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'galleries?populate[gallery_carousel][populate][image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiCarousel(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  const handleOption = (option) => {
    updateOption(option)
  }

  const CardWithIcon = ({ title, bg }) => {
    return (
      <>
        <div className="rounded-lg shadow-black/40 shadow-xl my-10">
          <div className="rounded-t-lg justify-items-center" style={{ height: '200px' }}>
            <img src={bg} className="w-full h-full rounded-t-lg" alt={title} />
          </div>
          <div className="grid justify-items-center dark:bg-slate-900/50 py-2 rounded-b-lg">
            <h3 className="text-slate-700/70 dark:text-white mt-5 text-base font-medium tracking-tight">
              {title}
            </h3>
            <button
              onClick={() => handleOption(title)}
              className="cursor-pointer inline-flex items-center justify-center p-0.5 border-2 border-green-300 text-xs mt-2 bg-green-400/20 dark:bg-green-800/20 dark:border-green-600/50 rounded-md shadow-lg">
              View gallery
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <section
      id="gallery"
      className="h-1/2 px-4 sm:px-6 md:px-8 pt-6 pb-6 bg-neutral-100 dark:bg-slate-800">
      {strapiContent?.map(({ gallery_carousel_title, cards, id }) => (
        <div key={id}>
          <h1 className="pt-5 text-center text-dark dark:text-white text-2xl font-medium uppercase md:text-3xl">
            <span className="text-slate-700/70 dark:text-slate-300">{gallery_carousel_title}</span>
          </h1>
          <div className="grid grid-cols-1 gap-8 mx-auto">
            <Carousel
              responsive={responsive}
              className="custom-carrousel"
              removeArrowOnDeviceType={['tablet', 'mobile']}>
                {cards.map(({ id, title, image }) => (
                  <div key={id} className="mx-4">
                    <CardWithIcon key={id} title={title} bg={image} />
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      ))}
    </section>
  )
}

export default Services
/* eslint-enable */
