/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import { getStrapiHeader } from './data/getStrapiHeader'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'headers?[populate][header_image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiHeader(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  const LinkForComputer = ({ path }) => (
    <Link
      to={path}
      spy
      smooth
      className="py-4 text-center capitalize px-2 hover:border-b-2 hover:bg-orange-600 text-gray-500 dark:text-white font-semibold dark:hover:text-white transition duration-300">
      {path}
    </Link>
  )

  const LinkForMobile = ({ path }) => (
    <Link
      to={path}
      spy
      smooth
      className="pl-2 block capitalize text-sm px-2 py-4  hover:bg-orange-600 hover:text-white dark:text-neutral-200 dark:hover:text-white transition duration-300">
      {path}
    </Link>
  )

  return (
    <nav className="bg-white dark:bg-slate-600/50">
      {strapiContent?.map(({id, header_name, header_logo, sections_on_page }) => (
        <div key={id} className="flex justify-between items-center px-8 md:px-10 xl:px-40 space-x-7">
          <a href="/" className='flex flex-row items-center py-4 px-2'>
            <img src={header_logo} alt="Logo" className="h-8 w-8 mr-2 rounded-2xl" />
            <span className="font-semibold md:text-md text-gray-500 dark:text-white text-lg">
              {header_name}
            </span>
          </a>
          <div className="hidden md:flex text-center text-white space-x-2">
            {sections_on_page.map((item, index) => (
              <LinkForComputer path={item.section} key={index} />
            ))}
          </div>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden flex justify-around items-center">
            <button className="outline-none mobile-menu-button pl-4">
              <svg
                className="w-6 h-6 text-gray-500 dark:text-white"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path d="M4 6h16M4 12h16M4 18h16"> </path>
              </svg>
            </button>
          </div>
        </div>
      ))}
      {isOpen && (
        <div className="mobile-menu px-8 sm:md:hidden block">
          <ul>
            {strapiContent?.map(({sections_on_page, id}) => (
              <div key={id}>
                {sections_on_page.map( item => (
                  <LinkForMobile path={item.section} key={item.section} />
                ))}
              </div>
            ))}
          </ul>
        </div>
      )}
    </nav>
  )
}

export default Header
