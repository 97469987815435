/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getStrapiServiceGallery } from './data/getStrapiServiceGallery'

const Gallery = ({ option }) => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'galleries?populate[gallery_services][populate][service_images]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiServiceGallery(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  const newOption = option.toString().toUpperCase();

  return (
    <section
      id="gallery"
      className="h-1/2 mx-auto px-4 sm:px-6 md:px-8 pt-6 pb-6 bg-neutral-100 dark:bg-slate-800">
      {strapiContent?.map(({ id, gallery_service_title, service_images }) => (
        <div key={id}>
          <h1 className="pt-5 text-center text-dark dark:text-white text-2xl font-medium uppercase md:text-3xl">
            <span className="text-slate-700/70 dark:text-slate-300">{gallery_service_title} </span>
          </h1>
          {service_images?.map(({ id: imgId, title, images }) => (
            <div key={imgId}>
              <section className="overflow-hidden text-gray-700">
                <div className="container flex flex-wrap px-5 py-2 mx-auto lg:px-32">
                  {newOption === title.toUpperCase() &&
                    images?.map(({ image, id: imgId }) => (
                      <div className="md:flex md:flex-wrap md:w-1/2" key={imgId}>
                        <div className="w-full p-1 md:p-2">
                          <img
                            alt="gallery"
                            className="block object-cover object-center w-full h-full rounded-lg"
                            src={image}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </section>
            </div>
          ))}
        </div>
      ))}
    </section>
  )
}

export default Gallery
/* eslint-enable */
